import { Component, Input, OnInit } from '@angular/core';
import { iOrderWorkflow } from '../model/workflow.model';
import { OrdersService } from '../services/order.service';
import { WorkflowService } from '../services/workflow.service';
import { MessageService } from "primeng/api";
import { iWorkflowStatus } from '../model/workflow-status.model';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-order-progress',
  templateUrl: './order-progress.component.html',
  styleUrl: './order-progress.component.css',
})
export class OrderProgressComponent implements OnInit
{
  @Input() orderUuid: string;
  workflowTableData: iOrderWorkflow[];
  workflowStatuses: iWorkflowStatus[];
  difficultyLevels =
  [
    { name: '1', id: 1 },
    { name: '2', id: 2 },
    { name: '3', id: 3 },
    { name: '4', id: 4 },
    { name: '5', id: 5 },
  ];
  displayDialog: boolean = false;
  description: string = '';
  type: any = null;
  queuedFiles: any[] = [];
  typeOptions: any[] =
  [
    { id: 1, name: 'Type A' },
    { id: 2, name: 'Type B' },
    { id: 3, name: 'Type C' }
  ];

  constructor(
    private orderService: OrdersService,
    private workflowService: WorkflowService,
    private messageService: MessageService
  ) {}

  ngOnInit()
  {
    this.fetchWorkflowData();
    this.fetchWorkflowStatuses();
  }

  private fetchWorkflowData()
  {
    this.orderService
      .getOrderWorkflowByOrderUuid(this.orderUuid)
      .subscribe({
          next: (workflows) => {
              console.log(workflows);
              this.workflowTableData = workflows.map((item) => ({
                  ...item,
                  rush: item.rush === 1,
              }));
          },
          error: (error) => {
              console.error('Error while getting orderWorkflow', error.message);
          },
      });
  }

  
  private fetchWorkflowStatuses()
  {
    this.workflowService.getWorkflowOptions().subscribe({
      next: (statuses) => {
        this.workflowStatuses = statuses;
        console.log(this.workflowStatuses);
      },
      error: (error) => {
        console.error('Error while getting workflow options', error);
      },
    });
  }

  updateRow(field: string, rowData: any)
  {
    console.log(`Updated ${field} to:`, rowData[field]);
    this.saveToDatabase(rowData);
  }

  saveToDatabase(workflow: iOrderWorkflow)
  {
    const payload =
    {
      ...workflow,
      rush: workflow.rush ? 1 : 0
    };
    this.workflowService.saveWorkflow(payload).subscribe({
      next: (response) => {
        console.log('Database updated successfully:', response);
      },
      error: (error) => {
        console.error('Error updating database:', error);
      },
    });
  }

  openDialog()
  {
    this.displayDialog = true;
  }

  closeDialog()
  {
    this.displayDialog = false;
  }

  onFileUpload(event: any): void {
    const uploadedFiles: File[] = event.files;
    console.log('Uploaded Files:', uploadedFiles); // Log uploaded files for debugging
  
    uploadedFiles.forEach((file) => {
      const formData = new FormData();
  
      // Generate a unique fileKey
      const timestamp = Date.now();
      const randomSuffix = Math.random().toString(36).substring(2, 8); // Add randomness
      const fileKey = `${timestamp}_${randomSuffix}_${file.name}`;
  
      // Append data to the form
      formData.append('fileKey', fileKey); // Unique identifier for the file
      formData.append('file', file); // The actual file
      formData.append('order_uuid', this.orderUuid || ''); // Replace with a dynamic value
      formData.append('document_type', 'SYS_FINAL'); // Replace with dynamic values if needed
      formData.append('file_name', file.name);
      formData.append('state', 'NY');
      formData.append('county', 'Tompkins');
      formData.append('description', 'Uploaded via PrimeNG');
      formData.append('status', 'active');
      formData.append('scope', 'public');
      formData.append('insert_without_order_uuid', 'false');
  
      // Log the FormData contents
      console.log('FormData contents:');
      formData.forEach((value, key) => console.log(`${key}:`, value));
  
      // Call the service to upload the file
      this.orderService.uploadDocument(formData).subscribe({
        next: (response) => {
          console.log(`File ${file.name} uploaded successfully`, response);
        },
        error: (error) => {
          console.error(`Failed to upload file ${file.name}`, error);
          // Show user-friendly error message here if needed
        },
      });
    });
  
    // Clear uploaded files from the UI using PrimeNG's clear() method
    if (event.originalEvent?.target?.clear) {
      event.originalEvent.target.clear(); // Clear the file input
    } else {
      console.warn('Ensure the file upload component supports clear().');
      // If the clear method isn't supported, reset manually if needed
    }
  }
    
}
