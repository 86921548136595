import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { iWorkflowStatus } from "../model/workflow-status.model";
import { iOrderWorkflow } from "../model/workflow.model";

@Injectable({
    providedIn: 'root'
})

export class WorkflowService
{
    constructor(private http: HttpClient) 
    { 
        // Nothing to do
    }

    getWorkflowOptions(): Observable<iWorkflowStatus[]>
    {
        return this.http.get<iWorkflowStatus[]>('api/orderWorkflows')
    }

    saveWorkflow(workflow: iOrderWorkflow): Observable<any>
    {
        return this.http.post<any>('api/saveWorkflow', workflow);
    }  
}