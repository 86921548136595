<p-table [value]="workflowTableData" responsiveLayout="scroll">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="workflowStatus" class="blue-tint-background">
          Workflow<p-sortIcon field="workflowStatus"></p-sortIcon>
        </th>
        <th pSortableColumn="workflowId" class="blue-tint-background">
          Service<p-sortIcon field="workflowId"></p-sortIcon>
        </th>
        <th pSortableColumn="priceLevel" class="blue-tint-background">
          Price Level<p-sortIcon field="priceLevel"></p-sortIcon>
        </th>
        <th pSortableColumn="rush" class="blue-tint-background">
          Rush<p-sortIcon field="rush"></p-sortIcon>
        </th>
        <th pSortableColumn="delivery" class="blue-tint-background">
          Delivery<p-sortIcon field="delivery"></p-sortIcon>
        </th>
        <th pSortableColumn="searcherId" class="blue-tint-background">
          Searcher ID<p-sortIcon field="searcherId"></p-sortIcon>
        </th>
        <th class="blue-tint-background">Actions</th> <!-- New Action column -->
      </tr>
    </ng-template>
  
    <ng-template pTemplate="body" let-rowData>
      <tr>
        <!-- Editable Workflow Status -->
        <td>
          <p-dropdown
            [options]="workflowStatuses"
            [(ngModel)]="rowData.workflowStatus"
            (onChange)="updateRow('workflowStatus', rowData)"
            optionLabel="name"
            optionValue="id"
            placeholder="Select Status"
            appendTo="body"
          ></p-dropdown>
        </td>
  
        <!-- Read-Only Workflow ID -->
        <td>{{ rowData.workflowName }}</td>
  
        <!-- Always Dropdown for Price Level -->
        <td>
          <p-dropdown
            [options]="difficultyLevels"
            [(ngModel)]="rowData.priceLevel"
            (onChange)="updateRow('priceLevel', rowData)"
            optionLabel="name"
            optionValue="id"
            appendTo="body"
          ></p-dropdown>
        </td>
  
        <!-- Checkbox for Rush -->
        <td>
          <p-checkbox
            [(ngModel)]="rowData.rush"
            binary="true"
            (onChange)="updateRow('rush', rowData)"
          ></p-checkbox>
        </td>
  
        <!-- Read-Only Delivery Date -->
        <td>{{ rowData.delivery ? (rowData.delivery | date: 'M/d/yyyy') : 'N/A' }}</td>
  
        <!-- Read-Only Searcher ID -->
        <td>{{ rowData.fullName ?? 'Unassigned' }}</td>
  
        <!-- Action Buttons Column -->
        <td>
          <!-- Email Button -->
          <p-button 
            pRipple 
            icon=" pi pi-file-edit" 
            class="mr-2" 
            [rounded]="true" 
            [outlined]="true" 
            severity="success"
            (onClick)="openDialog()"
          ></p-button>
  
          <!-- Delete Button -->
          <p-button 
            pRipple 
            icon="pi pi-file" 
            severity="success"
            [rounded]="true" 
            [outlined]="true"
          ></p-button>
        </td>
      </tr>
    </ng-template>
</p-table>

<p-dialog
  [(visible)]="displayDialog"
  header="Upload File"
  [style]="{ width: '50vw' }"
  [modal]="true"
  [closable]="false"
>
  <ng-template pTemplate="content">
    <div class="upload-container">
      <p-fileUpload
        name="files[]"
        [customUpload]="true"
        (uploadHandler)="onFileUpload($event)"
        [multiple]="true"
        dragDrop="true"
        accept=".pdf,.doc,.docx,.txt"
        [maxFileSize]="5000000"
        chooseLabel="Browse Files"
        uploadLabel="Upload"
        cancelLabel="Clear"
      ></p-fileUpload>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="dialog-footer">
      <button
        pButton
        label="Close"
        icon="pi pi-times"
        class="p-button-text"
        (click)="closeDialog()"
      ></button>
    </div>
  </ng-template>
</p-dialog>
