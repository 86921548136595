import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { iAgentOrder } from "../model/agent-order.model";
import { iOrderWorkflow } from "../model/workflow.model";
import { ApiResponse } from "../model/api-responce.model";

@Injectable({
    providedIn: 'root'
})

export class OrdersService
{
    constructor(private http: HttpClient) 
    { 
        // Nothing to do
    }

    getOrdersbyAgentId(agentId: string): Observable<iAgentOrder[]>
    {
        return this.http.get<iAgentOrder[]>('api/ordersByAgentId/' + agentId)
    }

    getAdditionalCostOptions(pid: string)
    {
        return this.http.get<any>("api/additionalCostOption/" + pid)
    }

    getOrderWorkflowByOrderUuid(orderUuid: string): Observable<iOrderWorkflow[]>
    {
        return this.http
            .get<ApiResponse<iOrderWorkflow[]>>(`/api/orderWorkflow/${orderUuid}`)
            .pipe(map(ApiResponse.unwrapApiResponse));
    }

    uploadDocument(formData: FormData): Observable<any> {
    
        return this.http.put('/api/documentUpload', formData,);
      }
}
